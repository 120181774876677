import Login from "./[company]/login/index.page";

const Home = (props: { refreshInitialData: () => void; locale: string }) => {
  return (
    <Login
      refreshInitialData={props.refreshInitialData}
      locale={props.locale}
      loginWithUsernameEnabled
    />
  );
};

Home.displayName = "Home";
Home.defaultProps = {
  noAuthNeeded: true,
};

export default Home;
